import React, { Component } from "react";
import styles from "./styles.module.less";
import marker from "./marker.png";

class Marker extends Component {
    render() {
        const { slug } = this.props;
        return (
            <a className={styles.Marker} href={slug}>
                <div
                    className={styles.container}
                    style={{ backgroundImage: `url(${marker})` }}>
                    {/* <div className={styles.locationValue}>{title}</div> */}
                </div>
            </a>
        );
    }
}
export default Marker;
