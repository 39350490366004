import React from "react";
import classnames from "classnames";
import styles from "./styles.module.less";

const SearchHero = ({
    children,
    customPhoto = "",
    compact = false,
    alt = "",
}) => {
    let cls = classnames(styles.container, {
        [styles.compact]: compact,
        customPhoto: !!customPhoto,
    });
    let style = {};
    if (!!customPhoto) {
        style.backgroundImage = "none";
    }
    return (
        <section className={styles.SearchHero}>
            <div className={cls} style={style}>
                <HeaderPhoto photo={customPhoto} alt={alt} />
            </div>
            {children}
        </section>
    );
};

const HeaderPhoto = ({ photo = "", alt = "" }) => {
    if (!photo) return null;
    return <img src={photo} alt={alt} />;
};

export default SearchHero;
