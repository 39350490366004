import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Header from "../../components/Header";
import Layout from "../../components/Layout";
import Search from "../../components/Search";

export default class FindYourLoanOfficer extends Component {
    render() {
        return (
            <Layout hasHero={true} header={<Header clearDba={true} />}>
                <Helmet>
                    <title>
                        Find Your Loan Officer | Bay Equity Home Loans
                    </title>
                    <meta
                        name="description"
                        content="Locate the loan officer nearest you by searching by city, state, zip or even the name of a Bay Equity loan officer. We look forward to helping you."
                    />
                    <meta
                        name="keywords"
                        content="loan officer,mortgage loan officer"
                    />
                </Helmet>
                <Search />
            </Layout>
        );
    }
}
