import React, { Component } from "react";
import { navigate } from "@reach/router";
import {
    get,
    groupBy,
    sortBy,
    filter,
    find,
    take,
    isEmpty,
    isNull,
    startsWith,
} from "lodash";
import {
    returnStateAbbrev,
    formatPhoneAreaCode,
    formatPhoneNumber,
} from "../../lib/helpers";

import haversine from "haversine-js";

import "url-search-params-polyfill";

import SearchMap from "../SearchMap";
import SearchHero from "../SearchHero";
import hero from "../../images/FindYourLoanOfficer-bg-NEW.jpg";
import LinkButton from "../LinkButton";

import { geolocateZip, geolocateAddress } from "../../lib/geo";

import { meetUsBranch, meetUsTeam, meetMe } from "../../lib/gtm";

import styles from "./styles.module.less";
import be from "../../images/bayequity_circle_mark.png";

const lng = "en"; // we only search for english results
const haversineOpts = {
    radius: haversine.EARTH.MILE,
};

export default class Search extends Component {
    constructor(props) {
        super(props);
        this.resultCount = React.createRef();
    }

    state = {
        searched: false,
        query: "",
        formattedAddress: "",
        results: [],
        branches: [],
        loanOfficers: [],
        teams: [],
        allBranches: [],
        showNearby: false, // are location services supported?
        working: false, // are we in the middle of searching?
    };

    /**
     * One search to rule them all
     *
     * * try an exact match first...
     * * Is this a zip code? Gelocate the zip code, Perform a search nearby
     * * Not a zip? Try a geo-location search?
     * * No results? Try a full text search
     */
    async search(query) {
        let loc, results;
        // (re) set default state
        this.setState({ working: true, formattedAddress: "", query });
        // try a title startsWith match
        results = this.searchByName(query);
        if (results.length && !results.some(res => res.type === "branch")) {
            return;
        }
        // try an exact match
        results = this.searchExactMatch(query);
        if (results.length && !results.some(res => res.type === "branch")) {
            return; // if the results contain a branch, don't return, do geolocate
        }
        // is this a zip code? Search by zip code
        loc = (await geolocateZip(query)) || (await geolocateAddress(query));
        if (!!loc) {
            const { lat, lon, state, formattedAddress } = loc || {};
            this.setState({ formattedAddress });
            results = this.nearest(lat, lon, state);

            // search based on searchVisibility
            let searchVisibilityResults = this.searchVisibilityOverride(state);

            // return if results or searchVisibilityResults are not empty
            if (results.length || searchVisibilityResults.length) return;
        }

        // If no zip results, back to a full text search…
        results = this.searchFullText(query);
    }

    /**
     * Attempt to search the list by name...
     *
     * @param {String} query
     */
    searchByName(query) {
        if (!query || !window.__LUNR__) return;
        const store = window.__LUNR__[lng].store;
        this.setState({ working: true, query });
        let cleaned = query
            .replace(/[^a-z0-9\s]/gi, "") // trim naughty characters
            .replace(/\b(a|an|the|i|am|be|it|him|her)\b/gi, "") // trim articles and fuzz
            .toLowerCase()
            .trim();
        let results = filter(store, item => {
            const { title } = item;
            return startsWith(title.toLowerCase(), cleaned);
        });
        const grouped = groupBy(results, "type");
        const branches = get(grouped, "branch", []);
        const loanOfficers = get(grouped, "loan-officer", []);
        const teams = get(grouped, "team", []);
        this.setState({
            branches,
            loanOfficers,
            teams,
            results,
            working: false,
            searched: true,
        });
        return results;
    }

    searchExactMatch(query) {
        if (!query || !window.__LUNR__) return;
        this.setState({ working: true, query });
        let cleaned = query
            .replace(/[^a-z0-9\s]/gi, "") // trim naughty characters
            .replace(/\b(a|an|the|i|am|be|it|him|her)\b/gi, "") // trim articles and fuzz
            .trim();
        let q =
            "+" +
            cleaned
                .split(/\s/)
                .filter(f => !!f)
                .join(" +");
        const lunrIndex = window.__LUNR__[lng];
        let hits = lunrIndex.index.search(q);
        const results = hits.map(({ ref }) => lunrIndex.store[ref]);
        const grouped = groupBy(results, "type");
        const branches = get(grouped, "branch", []);
        let loanOfficers = get(grouped, "loan-officer", []);
        const teams = get(grouped, "team", []);

        this.mapBranch(loanOfficers);
        this.mapBranch(teams);

        this.setState({
            query,
            results,
            branches,
            loanOfficers,
            teams,
            working: false,
            searched: true,
        });
        return results;
    }

    /**
     * Perform a full-text search of the Lunr search index.
     * Store results in state
     * @param {String} query
     */
    searchFullText(query) {
        if (!query || !window.__LUNR__) return;
        this.setState({ working: true });
        const lunrIndex = window.__LUNR__[lng];
        let q = `title:${query}`;
        let hits = lunrIndex.index.search(q);
        if (hits.length === 0) {
            // exact match not found, try a wildcard search
            let q = `title:${query}*`;
            hits = lunrIndex.index.search(q);
        }
        const results = hits.map(({ ref }) => lunrIndex.store[ref]);
        const grouped = groupBy(results, "type");
        const branches = get(grouped, "branch", []);
        const loanOfficers = get(grouped, "loan-officer", []);
        const teams = get(grouped, "team", []);

        this.mapBranch(loanOfficers);
        this.mapBranch(teams);

        this.setState({
            query,
            results,
            branches,
            loanOfficers,
            teams,
            working: false,
            searched: true,
        });
        return results;
    }

    /**
     * Return the 10 nearest by the provided coordinates
     *
     * @param {Number} latitude
     * @param {Number} longitude
     */
    nearest(latitude, longitude, state) {
        // state as in US State
        if (!latitude || !longitude || !window.__LUNR__) return;
        const store = window.__LUNR__[lng].store;
        let filterBy = {};
        if (state && !isEmpty(state)) filterBy.state = state;
        const filtered = filter(store, item => {
            if (state) {
                return (
                    item.state ===
                        (filterBy.state && filterBy.state.long_name) ||
                    item.state === (filterBy.state && filterBy.state.short_name)
                );
            }
            return item;
        });
        const withDistance = filtered.map(branch => {
            let here = {
                latitude,
                longitude,
            };
            let there = {
                latitude: branch.lat,
                longitude: branch.lon,
            };
            let distance = haversine(here, there, haversineOpts);
            return {
                distance,
                ...branch,
            };
        });
        let results = sortBy(withDistance, "distance");
        if (!state) {
            results = take(results, 50);
        }
        const grouped = groupBy(results, "type");
        const branches = get(grouped, "branch", []);
        const loanOfficers = get(grouped, "loan-officer", []);
        const teams = get(grouped, "team", []);
        this.setState({
            branches,
            loanOfficers,
            teams,
            results,
            working: false,
            searched: true,
        });
        return branches;
    }

    searchVisibilityOverride(state) {
        // state as in US State
        if (!state || isEmpty(state) || !window.__LUNR__) return;
        const store = window.__LUNR__[lng].store;
        let filterBy = { state: state };
        let results = [];

        // if state is set, search the searchVisility field for the state value
        if (state) {
            results = filter(store, item => {
                // state is in item's searchVisibility field
                const isInState = item.searchVisibility.includes(
                    filterBy.state.short_name,
                );
                // loan officer has not yet been added to search results
                const isNewResult =
                    this.state.loanOfficers.filter(
                        loanOfficer => loanOfficer.title === item.title,
                    ).length === 0;
                return isInState && isNewResult;
            });
        } else return;

        // set location and branch fields to null for searchVisility results
        const resultsCleaned = results.map(item => {
            return {
                address: null,
                branch: null,
                city: null,
                lat: null,
                license: item.license,
                lon: null,
                phone: null,
                photo: item.photo,
                searchVisibility: item.searchVisibility,
                slug: item.slug,
                state: null,
                title: item.title,
                type: item.type,
                zip: null,
            };
        });

        let resultsCleanedSorted = sortBy(resultsCleaned, "title");

        // append searchVisibility results to loanOfficers and results
        this.setState({
            loanOfficers: this.state.loanOfficers.concat(resultsCleanedSorted),
            results: this.state.results.concat(resultsCleanedSorted),
        });

        return resultsCleaned;
    }

    getBranchByTitle(title) {
        if (!title || !window.__LUNR__) return;
        const store = window.__LUNR__[lng].store;
        const branch = find(store, { type: "branch", title });
        return branch;
    }

    getAllBranches() {
        if (!window.__LUNR__) return;
        const lunrIndex = window.__LUNR__[lng];
        const store = lunrIndex.store;
        const branches = filter(store, { type: "branch" });
        return branches;
    }

    getAllLoanOfficers() {
        if (!window.__LUNR__) return;
        const lunrIndex = window.__LUNR__[lng];
        const store = lunrIndex.store;
        const los = filter(store, { type: "loan-officer" });
        return los;
    }

    getAllTeams() {
        if (!window.__LUNR__) return;
        const lunrIndex = window.__LUNR__[lng];
        const store = lunrIndex.store;
        const team = filter(store, { type: "team" });
        return team;
    }

    reset() {
        this.setState({
            results: [],
            branches: [],
            loanOfficers: [],
            teams: [],
        });
    }

    mapBranch(collection) {
        collection.forEach(i => {
            let title = get(i, "branch", "");
            let branch = this.getBranchByTitle(title);
            if (!branch) return;
            const { address, city, state, zip, lat, lon } = branch;
            Object.assign(i, {
                address,
                city,
                state,
                zip,
                lat,
                lon,
            });
        });
    }

    handleChange = event => {
        let query = event.target.value;
        this.setState({ query });
    };

    handleClickSearch = async event => {
        let { query } = this.state;
        if (!query) return;
        this.reset();
        navigate(`?query=${query}`);
        await this.search(query);
        // scroll results into view
        if (this.resultCount.current) {
            this.resultCount.current.scrollIntoView({ behavior: "smooth" });
        }
    };

    handleKeyPress = async event => {
        if (event.key === "Enter") {
            await this.handleClickSearch();
        }
    };

    handleClickNearest = event => {
        event.preventDefault();
        this.reset();
        navigate(`?nearest`);
        this.getLocationAndSearch();
    };

    /**
     * Get the user's current lat / lon,
     * then trigger a lat / lon based search
     */
    getLocationAndSearch() {
        const { showNearby } = this.state;
        if (showNearby) {
            this.setState({ working: true });
            navigator.geolocation.getCurrentPosition(position => {
                const { latitude, longitude } = position.coords;
                this.nearest(latitude, longitude);
            });
        }
    }

    componentDidMount() {
        this.setState({ showNearby: "geolocation" in navigator });

        if (window && window.__LUNR__) {
            let params = new URLSearchParams(window.location.search);
            let query = params.get("query");
            let nearest = params.has("nearest");

            window.__LUNR__.__loaded.then(() => {
                // make all branches available for mapping
                const allBranches = this.getAllBranches();
                this.setState({ allBranches });

                // map branches to LOs and Teams
                this.mapBranch(this.getAllLoanOfficers());
                this.mapBranch(this.getAllTeams());

                if (nearest) {
                    this.getLocationAndSearch();
                    return;
                }
                if (query) {
                    this.search(query);
                    return;
                }
            });
        }
    }

    renderResult(result, i) {
        const {
            slug,
            type,
            title,
            photo = be,
            phone,
            address,
            city,
            state,
            zip,
            license,
        } = result;
        let img = photo || be;
        let label = type === "loan-officer" ? "Meet Me" : "Meet Us";
        //meetUsBranch, meetUsTeam, meetMe
        let gtm;
        switch (type) {
            case "branch":
                gtm = meetUsBranch;
                break;
            case "team":
                gtm = meetUsTeam;
                break;
            case "loan-officer":
            default:
                gtm = meetMe;
                break;
        }
        let bugIsIcon =
            img.includes("beicon") || img.includes("be-circle-mark")
                ? true
                : false;

        return (
            <div key={i} className={styles.result}>
                <div className={styles.imageContainer}>
                    <img
                        src={img}
                        alt={title}
                        className={bugIsIcon ? styles.icon : styles.photo}
                    />
                </div>
                <div className={styles.resultInfo}>
                    <div className={styles.loader} />
                    <div className={styles.description}>
                        <h3>{title}</h3>
                        <p>
                            {!!license && (
                                <span className={styles.license}>
                                    {license}
                                </span>
                            )}
                            {!!address &&
                                `${address} ${city}, ${state &&
                                    returnStateAbbrev(state)} ${zip}`}
                            {phone && (
                                <>
                                    <br />
                                    <a href={`tel:${formatPhoneNumber(phone)}`}>
                                        {formatPhoneAreaCode(phone)}
                                    </a>
                                </>
                            )}
                        </p>
                    </div>
                    <div className={styles.cta}>
                        <LinkButton label={label} href={slug} dataGtm={gtm} />
                    </div>
                </div>
            </div>
        );
    }

    renderBranches() {
        const { branches } = this.state;
        if (branches.length === 0) return null;
        return (
            <section className={styles.results}>
                <h2>Branches</h2>
                {branches.map(this.renderResult)}
            </section>
        );
    }

    renderLoanOfficers() {
        const { loanOfficers } = this.state;
        if (loanOfficers.length === 0) return null;
        return (
            <section className={styles.results}>
                <h2>Loan Officers</h2>
                {loanOfficers.map(this.renderResult)}
            </section>
        );
    }

    renderTeams() {
        const { teams } = this.state;
        if (teams.length === 0) return null;
        return (
            <section className={styles.results}>
                <h2>Teams</h2>
                {teams.map(this.renderResult)}
            </section>
        );
    }

    renderNoResults(searched, working, results, query) {
        if (!searched || working || results.length) return null;
        return (
            <section className={styles.results}>
                <h2>No results found for “{query}”</h2>
                <div className={styles.result}>
                    <p>
                        Try another search or <a href="/contact-us">contact</a>{" "}
                        us to see if we can help.
                    </p>
                </div>
            </section>
        );
    }

    renderMap() {
        const { searched, results, allBranches } = this.state;
        // results returned, loan officers, teams and branches can be locations

        // if loan officers and teams, and their branch is in the array, filter out so they don't get a pin
        const filteredResults = filter(results, res => {
            const isBranchInResult = results.some(
                result => result.title === res.branch,
            );
            if (
                (isBranchInResult &&
                    (res.type === "loan-officer" || res.type === "team")) ||
                isNull(res.lat) ||
                isNull(res.lon)
            ) {
                return false;
            }
            return res;
        });
        if (searched && filteredResults.length) {
            return <SearchMap key="map" locations={filteredResults} />;
        }
        // no search performed, no query - empty page
        if (!searched && allBranches.length) {
            return <SearchMap locations={allBranches} />;
        }
        // search result contains only teams and LOs
        return null;
    }

    render() {
        const {
            query,
            results,
            showNearby,
            working,
            searched,
            formattedAddress,
        } = this.state;
        return (
            <div className={styles.Search}>
                <SearchHero
                    customPhoto={hero}
                    alt="Row of Homes - Find Your Loan Officer | Bay Equity Home Loans"
                />
                <section className={styles.SearchBox}>
                    <p>
                        Find your loan officer
                        {/* <span>2 results found near "Portland, Oregon"</span> */}
                    </p>
                    <input
                        className={styles.TextField}
                        aria-label="Find your loan officer"
                        type="text"
                        defaultValue={query}
                        onChange={this.handleChange}
                        onKeyPress={this.handleKeyPress}
                    />
                    <br />
                    <button
                        className={styles.Button}
                        aria-label="Search"
                        type="button"
                        onClick={this.handleClickSearch}>
                        Search
                    </button>
                    <br />
                    {!!showNearby && (
                        <button
                            className={styles.Button}
                            aria-label="Search for loan officers near me"
                            type="button"
                            onClick={this.handleClickNearest}>
                            Near Me
                        </button>
                    )}
                </section>
                {this.renderMap()}
                {searched && (
                    <ResultsCount
                        ref={this.resultCount}
                        results={results}
                        formattedAddress={formattedAddress}
                        working={working}
                        query={query}
                    />
                )}
                {this.renderBranches()}
                {this.renderLoanOfficers()}
                {this.renderTeams()}
                {this.renderNoResults(searched, working, results, query)}
            </div>
        );
    }
}

const ResultsCount = React.forwardRef(
    ({ results, working, formattedAddress }, ref) => {
        let inLocale = !!formattedAddress ? ` in ${formattedAddress}` : "";
        return (
            <section ref={ref} className={styles.resultsCount}>
                {working ? (
                    <h1>Searching…</h1>
                ) : (
                    <h1>
                        Search Results{inLocale}: ({results.length})
                    </h1>
                )}
            </section>
        );
    },
);
