import React, { Component } from "react";
import GoogleMapReact from "google-map-react";
import Marker from "./Marker";

class SearchMap extends Component {
    componentDidUpdate(prevProps) {
        if (prevProps.locations.length !== this.props.locations.length) {
            this.fit();
        }
    }

    apiLoaded = ({ map, maps }) => {
        this.map = map;
        this.maps = maps;
        this.fit();
    };

    fit() {
        if (!this.maps) return;
        const { locations = [] } = this.props;
        if (locations.length === 1) return;
        const bounds = new this.maps.LatLngBounds();
        locations.forEach(loc => {
            bounds.extend(new this.maps.LatLng(loc.lat, loc.lon));
        });
        this.map.fitBounds(bounds);
    }

    render() {
        const { locations } = this.props;
        const ApiKey = process.env.GATSBY_GOOGLE_MAP_API_KEY;

        return (
            // Important! Always set the container height explicitly
            <section
                onClick={this.handleInteraction}
                onScroll={this.handleInteraction}
                style={{ height: "345px", width: "100%" }}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: ApiKey }}
                    defaultCenter={[locations[0].lat, locations[0].lon]}
                    center={[locations[0].lat, locations[0].lon]}
                    defaultZoom={11}
                    yesIWantToUseGoogleMapApiInternals={true}
                    onGoogleApiLoaded={this.apiLoaded}>
                    {locations.map((loc, i) => (
                        <Marker
                            key={i}
                            slug={loc.slug}
                            lat={loc.lat}
                            lng={loc.lon}
                            title={++i}
                        />
                    ))}
                </GoogleMapReact>
            </section>
        );
    }
}

SearchMap.defaultProps = {
    locations: [],
};

export default SearchMap;
